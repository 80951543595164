.dark-theme {
  --colors-selected: #e3c050 !important;
  --hms-ui-colors-white: #ffffff;
  --hms-ui-colors-secondary: #8c8e95;
  --hms-ui-colors-light: #C0C0C0;
  --hms-ui-colors-danger: #FB1751;
  --hms-ui-colors-black: #000;
  --hms-ui-colors-gray: #575757;
  --hms-ui-colors-dark: #4e4e4e;
  --hms-ui-colors-gray-gradient: linear-gradient(180deg,
      #1f1f1f 0%,
      rgba(31, 31, 31, 0.8) 100%);
  --hms-ui-colors-transparent: #00000000;
  --hms-ui-colors-yellow: #e3c050 !important;
  --hms-ui-colors-danger: #E64135 !important;
  --hms-ui-colors-borderDefault: #373737 !important;
  --hms-ui-colors-border_default: #454648 !important;
  --hms-ui-colors-borderDefault2: rgba(74, 74, 74, 0.50);
  --hms-ui-colors-borderLight: #373737 !important;
  --hms-ui-colors-borderAccent: #373737 !important;
  --hms-ui-colors-success: #35764d !important;
  --hms-ui-colors-warning: #ffab00;
  --hms-ui-colors-brandLight: #fff968;
  --hms-ui-colors-brandDefault: #e3c050 !important;
  --hms-ui-colors-brandDark: #9e8638;
  --hms-ui-colors-brandDisabled: #f9d358;
  --hms-ui-colors-grayLight: #b0c3db;
  --hms-ui-colors-grayDark: #303740;
  --hms-ui-colors-secondaryGray: #242a33;
  --hms-ui-colors-grayDisabled: #dce4ef;
  --hms-ui-colors-textHighEmp: #f5f9fff2;
  --hms-ui-colors-textMedEmp: rgba(224, 236, 255, 0.8);
  --hms-ui-colors-textDisabled: #ffffff61;
  --hms-ui-colors-textAccentHigh: #fffffffa;
  --hms-ui-colors-textAccentMedium: #ffffffb8;
  --hms-ui-colors-textAccentDisabled: #ffffff7a;
  --hms-ui-colors-surfaceLight: #131313 !important;
  --hms-ui-colors-menuBg: #1f1f1f !important;
  --hms-ui-colors-surface_default: #2b2b2b !important;
  --hms-ui-colors-surface_dim: #1f1f1f !important;
  --hms-ui-colors-surface_bright: #2B2B2B !important;
  --hms-ui-colors-surface_brighter: #2B2B2B !important;
  --hms-ui-colors-border_bright: #454648 !important;
  --hms-ui-colors-on_surface_high: #EDEDED !important;
  --hms-ui-colors-on_surface_low: #8C8E95 !important;
  --hms-ui-colors-backgroundDefault: #131313 !important;
  --hms-ui-colors-backgroundDefault2: rgba(19, 19, 19, 0.50);
  --hms-ui-colors-backgroundDark: #131313 !important;
  --hms-ui-colors-backgroundDarker: #131313 !important;
  --hms-ui-colors-primaryLight: #2b2b2b !important;
  --hms-ui-colors-background_default: #2B2B2B !important;
  --hms-ui-colors-secondary_dim: #454648 !important;
  --hms-ui-colors-secondary_default: #454648 !important;
  --hms-ui-colors-primaryLightBorder: #454648 !important;
  --hms-ui-colors-primaryDefault: #e3c050 !important;
  --hms-ui-colors-primary_default: #e3c050 !important;
  --hms-ui-colors-primary_dim: #e3c050 !important;
  --hms-ui-colors-primary_bright: #e3c050 !important;
  --hms-ui-colors-primaryDark: #e3c050 !important;
  --hms-ui-colors-primaryDisabled: #e3c050 !important;
  --hms-ui-colors-secondaryLight: #6b7d99;
  --hms-ui-colors-secondaryDefault: #545454 !important;
  --hms-ui-colors-secondaryDark: #545454 !important;
  --hms-ui-colors-secondaryDisabled: #545454 !important;
  --hms-ui-colors-error: #E64135 !important;
  --hms-ui-colors-errorTint: #fb1404 !important;
  --hms-ui-colors-errorDark: #bf180b !important;
  --hms-ui-colors-errorDefault: #E64135 !important;
  --hms-ui-colors-errorLight: rgba(230, 110, 110, 1) !important;
  --hms-ui-colors-errorDisabled: #a44848 !important;
  --hms-ui-colors-alert_error_default: #E64135 !important;
  --hms-ui-colors-alert_error_bright: #fb1404 !important;
  --hms-ui-colors-bgPrimary: #000 !important;
  --hms-ui-colors-bgSecondary: #1d232b;
  --hms-ui-colors-bgTertiary: #303740;
  --hms-ui-colors-bg37: #373737;
  --hms-ui-colors-textPrimary: #e9e9e9 !important;
  --hms-ui-colors-textSecondary: #ffffffb8;
  --hms-ui-colors-textInvert: rgba(0, 0, 0, 0.9);
  --hms-ui-colors-iconBtn: var(--hms-ui-colors-white);
  --hms-ui-colors-iconHoverBg: rgba(255, 255, 255, 0.25);
  --hms-ui-colors-transparentBg: rgba(0, 0, 0, 0.22);
  --hms-ui-colors-mainBg: #1F1F1F;
  --hms-ui-colors-tileBg: #0f1115;
  --hms-ui-colors-previewBg: #111111;
  --hms-ui-colors-statsBg: rgba(0, 0, 0, 0.75);
  --hms-ui-colors-inputBg: var(--hms-ui-colors-bgSecondary);
  --hms-ui-colors-toastBg: #303740;
  --hms-ui-colors-borderLighter: #373737 !important;
  --hms-ui-shadows-sm: 0 0 4px 0 #0000004d;
  --hms-ui-shadows-md: 0 0 8px 0 #0000004d;
  --hms-ui-fonts-sans: Roboto, Inter, sans-serif !important;
  --hms-ui-fonts-heading: "Playfair Display", serif !important;
  --hms-ui-fontSizes-h1: 6rem;
  --hms-ui-fontSizes-h2: 3.75rem;
  --hms-ui-fontSizes-h3: 3rem;
  --hms-ui-fontSizes-h4: 2.125rem;
  --hms-ui-fontSizes-h5: 1.5rem;
  --hms-ui-fontSizes-h6: 1.25rem;
  --hms-ui-fontSizes-tiny: 0.625rem;
  --hms-ui-fontSizes-xs: 0.75rem;
  --hms-ui-fontSizes-sm: 0.875rem;
  --hms-ui-fontSizes-md: 1rem;
  --hms-ui-fontSizes-lg: 1.125rem;
  --hms-ui-fontWeights-semiBold: 600;
  --hms-ui-fontWeights-medium: 500;
  --hms-ui-fontWeights-regular: 400;
  --hms-ui-lineHeights-h1: 5.75rem;
  --hms-ui-lineHeights-h2: 3.5rem;
  --hms-ui-lineHeights-h3: 3.25rem;
  --hms-ui-lineHeights-h4: 2.5rem;
  --hms-ui-lineHeights-h5: 2rem;
  --hms-ui-lineHeights-h6: 1.5rem;
  --hms-ui-lineHeights-tiny: 1rem;
  --hms-ui-lineHeights-xs: 1rem;
  --hms-ui-lineHeights-sm: 1.25rem;
  --hms-ui-lineHeights-md: 1.5rem;
  --hms-ui-radii-0: 0.25rem;
  --hms-ui-radii-1: 0.5rem;
  --hms-ui-radii-2: 0.75rem;
  --hms-ui-radii-3: 1rem;
  --hms-ui-radii-4: 1.5rem;
  --hms-ui-radii-5: 0.375rem;
  --hms-ui-radii-round: 100rem;
  --hms-ui-space-0: 0rem;
  --hms-ui-space-1: 0.125rem;
  --hms-ui-space-2: 0.25rem;
  --hms-ui-space-3: 0.375rem;
  --hms-ui-space-4: 0.5rem;
  --hms-ui-space-5: 0.625rem;
  --hms-ui-space-6: 0.75rem;
  --hms-ui-space-7: 0.875rem;
  --hms-ui-space-8: 1rem;
  --hms-ui-space-9: 1.25rem;
  --hms-ui-space-10: 1.5rem;
  --hms-ui-space-11: 1.75rem;
  --hms-ui-space-12: 2rem;
  --hms-ui-space-13: 2.25rem;
  --hms-ui-space-14: 2.5rem;
  --hms-ui-space-15: 2.75rem;
  --hms-ui-space-16: 3rem;
  --hms-ui-space-17: 3.5rem;
  --hms-ui-space-18: 4rem;
  --hms-ui-space-19: 4.25rem;
  --hms-ui-space-20: 5rem;
  --hms-ui-space-24: 6rem;
  --hms-ui-space-28: 7rem;
  --hms-ui-space-32: 8rem;
  --hms-ui-space-36: 9rem;
  --hms-ui-space-40: 10rem;
  --hms-ui-space-44: 11rem;
  --hms-ui-space-48: 12rem;
  --hms-ui-space-52: 13rem;
  --hms-ui-space-56: 14rem;
  --hms-ui-space-60: 15rem;
  --hms-ui-space-64: 16rem;
  --hms-ui-space-72: 18rem;
  --hms-ui-space-80: 20rem;
  --hms-ui-space-96: 24rem;
  --hms-ui-space-100: 27rem;
  --hms-ui-space-xs: 0.5rem;
  --hms-ui-space-sm: 0.75rem;
  --hms-ui-space-md: 1rem;
  --hms-ui-space-lg: 1.25rem;
  --hms-ui-space-xl: 2.25rem;
  --hms-ui-space-px: 1px;
  --hms-ui-borderWidths-light: 1px;
  --hms-ui-borderWidths-normal: 2px;
  --hms-ui-borderWidths-bold: 3px;
  --hms-ui-borderWidths-extrabold: 4px;
  --hms-ui-borderWidths-black: 5px;
}

button[class*="-variant-primary"],
button[class*="-variant-standard"] {
  background: var(--hms-ui-colors-black) !important;
  border-color: var(--hms-ui-colors-primaryDefault) !important;
}

button[class*="-variant-primary"]:hover,
button[class*="-variant-primary"]:active,
button[class*="-variant-primary"]:focus,
button[class*="-variant-standard"]:hover,
button[class*="-variant-standard"]:active,
button[class*="-variant-standard"]:focus {
  background: var(--hms-ui-colors-primaryLight) !important;
  border-color: var(--hms-ui-colors-primaryDefault) !important;
}


body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--hms-ui-colors-mainBg);
}

html,
body {
  height: 100%;
  pointer-events: inherit !important;
  top: 0px !important;
}

#six-events-directory {
  left: 0;
  margin: 0;
  overscroll-behavior-y: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--hms-ui-colors-mainBg);
  color: var(--hms-ui-colors-secondary);
}

/* scroll bar */
::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
  background: #373737 !important;
}

::-webkit-scrollbar-track {
  background: #373737 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: var(--hms-ui-colors-gray) !important;
  border-radius: 10px !important;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

/**
 * needed for safari. Safari
 * adds background color on its own
 * on hls-viewer on fullscreen
 */
#hls-viewer-dark:fullscreen {
  background-color: black !important;
}

#hls-viewer-light:fullscreen {
  background-color: white !important;
}

textarea {
  font-family: var(--hms-ui-fonts-sans);
  background-color: var(--hms-ui-colors-mainBg);
  border-radius: 8px;
  outline: none;
  border: 1px solid val(--hms-ui-colors-borderDefault);
  padding: 0.5rem 0.75rem;
  min-height: 30px;
  color: var(--hms-ui-colors-textPrimary);
  font-size: var(--hms-ui-fontSizes-md);
  width: 100%;
}