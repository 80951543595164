:root {
    --plyr-video-control-background-hover: var(--hms-ui-colors-primaryLight);
    --plyr-progress-marker-background: #e9e9e9;

    --off-white-color: #e9e9e9;
    --secondary-gray-color: #8c8e95;
    --yellow-color: #e3c050;
}

/* common classes */
.margin-left-auto-plyr {
    margin-left: auto;
}

.background-transparent-plyr {
    background-color: transparent;
}

.text-overflow-ellipsis-plyr {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-decoration-none-plyr {
    text-decoration: none;
}

.com-btn-plyr {
    border: 1px solid #e3c050;
    padding: 12px 28px;
    background-color: #000;
    color: #e9e9e9;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}

.light-gray-bg-plyr {
    background-color: rgba(30, 30, 30, 1);
}

.off-white-text-plyr {
    color: var(--off-white-color);
}

.secondary-gray-text-plyr {
    color: var(--secondary-gray-color);
}

.light-gray-text-plyr {
    color: rgba(30, 30, 30, 1);
}

.white-color-text-plyr {
    color: #fff;
}

.display-block-plyr {
    display: block;
}

.display-none {
    display: none !important;
}


.com-heading-plyr {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 500;
}

.com-divider-plyr {
    height: 1px;
    width: 100%;
    background-color: #1f1f1f;
    margin: 23px 0;
}

/* common classes end*/

.plyr-video-body * {
    box-sizing: border-box;
}

.plyr-video-body {
    color: #e9e9e9;
    position: static !important;
    min-height: auto;
}

.skiptranslate,
.goog-gt-tt {
    display: none !important;
}

.video-wrap-body font {
    background-color: transparent !important;
    box-shadow: none !important;
}

.front-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    z-index: 99999;
}

.video-wrap-body p {
    font-size: 15px;
    color: var(--secondary-gray-color);
}

.plyr__progress__marker {
    height: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #606060;
}

.video-wrap-body {
    display: flex;
    justify-content: center;
    background-color: #000;
}

.video-wrap {
    position: relative;
    width: 100%;
}

.video-wrap-overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 1;
    cursor: pointer;

}

.video-wrap video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 100vh;
}




.plyr__control--overlaid {
    background-color: #000 !important;
    border: 5px solid #acacac;
    height: 100px;
    width: 100px !important;
}

.plyr__control--overlaid use {
    color: #d9d9d9;
}

.plyr__control--overlaid svg {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    height: 36px;
    width: 36px;
}

.plyr--video {
    border-radius: 0;
}

.plyr--video .plyr__controls {
    padding: 0;
    height: 44px;
    background-color: #000;
}

.plyr__controls.hidden {
    display: none !important;
}

.plyr__progress input[type=range] {
    color: #E9E9E9;
}

.plyr__progress__buffer {
    background: var(--hms-ui-colors-primaryLight);
}

.vid-player-wrap {
    position: relative;
    width: 860px;
    max-width: 94vw;
}

.click-for-sound {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0px;
    cursor: pointer;
}

.click-for-sound-btn {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 12px;
    padding: 0 16px;
    height: 55px;
    border: none;
    border-radius: 40px;
    background-color: #131313 !important;
    color: #E9E9E9;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    min-width: 55px;
    box-sizing: border-box;
}

.click-for-sound-text {
    display: none;
}

.click-for-sound img {
    width: 23px;
}

.video-wrap:hover .click-for-sound-text {
    display: block;
}

.plyr__controls__item .plyr__control,
button.plyr__controls__item {
    height: 44px;
    width: 42px;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    color: #e9e9e9;
}

.plyr__controls__item .plyr__control:hover,
button.plyr__controls__item:hover {
    background-color: var(--hms-ui-colors-primaryLight);
}

.plyr__controls__item.plyr__volume {
    position: relative;
}

.plyr__controls__item.plyr__volume input {
    display: none;
    position: absolute;
    background-color: #13131396;
    text-align: center;
    padding: 22px 12px;
    width: 120px;
    border-radius: 0;
    left: 50%;
    transform: rotate(-90deg) !important;
    -webkit-transform: rotate(-90deg) !important;
    -moz-transform: rotate(-90deg) !important;
    -o-transform: rotate(-90deg) !important;
    -ms-transform: rotate(-90deg) !important;
    transform-origin: left;
    bottom: 50%;
    top: -20px;
    width: 110px !important;
    max-width: none;
    margin: 0;
    color: #e9e9e9;
}

.plyr__controls__item.plyr__volume:hover input {
    display: block;
}

.plyr__controls__item.plyr__menu {
    position: static;
}

.plyr__menu__container {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    -o-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    margin: 0;
    bottom: 146px;
    border-radius: 11px;
    background-color: #131313;
    color: #8C8E95;
    font-size: 14px;
    padding: 4px;
}

.plyr__menu__container .plyr__control {
    width: 100%;
    margin: 0;
}

.speed-control-area {
    display: none;
    z-index: 1;
    border-radius: 11px;
    background-color: #131313;
    color: #8C8E95;
    font-size: 14px;
    padding: 4px;
    overflow: hidden;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    white-space: nowrap;
}

.speed-control-area.speed-control-area-animated {
    bottom: 150px;
}

.speed-control-area.visible {
    display: block;
}

.vid-light-text {
    color: #e9e9e9;
}

.speed-control-area .active {
    color: #e9e9e9 !important;
}

.speed-control-area-row {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
}

.speed-control-area-num {
    margin-bottom: 4px;
}

.speed-control-area-num .x-btn.active {
    transform: scale(1.14);
    -webkit-transform: scale(1.14);
    -moz-transform: scale(1.14);
    -o-transform: scale(1.14);
    -ms-transform: scale(1.14);
    transform-origin: center;
}

.speed-control-area-num .x-btn {
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
    color: inherit;
}

.speed-control-area-num .x-btn:hover {
    color: #fff !important;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
}

.speed-control-area-time {
    background-color: var(--hms-ui-colors-primaryLight);
    border-radius: 0 0 9px 9px;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
    display: none;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid.visible {
    display: block !important;
    opacity: 1;
    visibility: visible;
}

.speed-control-area-animated {
    /* display: block !important; */
}

.speed-control-area-animated .x-btn {
    display: none;
}

.speed-control-area-animated .x-btn.active {
    display: block;
}

.speed-control-area-animated .speed-control-area-num {
    width: fit-content;
    margin: 0 auto 4px;
}

.speed-control-area-animated .speed-control-area-num>svg {
    color: var(--yellow-color);
}

.speed-control-area-animated .speed-control-area-num:hover .x-btn {
    display: block;
}

.speed-control-area-animated .speed-control-area-num:hover .speed-icon {
    display: none;
}

.speed-control-area-animated .vid-light-text.updated {
    animation: upside 0.3s linear;
}

@keyframes upside {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.caption-list {
    display: none;
    position: absolute;
    bottom: calc(100% + 20px);
    right: 20px;
    z-index: 1;
    background-color: var(--hms-ui-colors-primaryLight);
    color: #e9e9e9;
    /* max-width: 270px; */
    min-width: 160px;
    max-height: 190px;
    /* overflow-y: hidden; */
    padding: 2px 0;
    border-radius: 6px;
    font-size: 16px;

    max-width: 660px;
    overflow-y: auto;
    max-height: 250px;
}

.video-frame-separate .caption-list {
    /* max-width: 660px;
  overflow-y: auto;
  max-height: 250px; */
}

.caption-list.visible {
    display: block;
}

.caption-list-short-name {
    text-transform: uppercase;
    background-color: #000;
    padding: 1px 5px;
    margin-left: auto;
    font-size: 13px;
    border: 1px solid var(--hms-ui-colors-primaryLight);
    border-radius: 4px;
    min-width: 17px;
    text-align: center;
    flex-shrink: 0;
}

.caption-list-single input {
    display: none;
}

.caption-list-single input:checked+.caption-list-single-label {
    background-color: #000;
}

.caption-list-single-label {
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
}

.caption-list-single-label:hover {
    background-color: #000;
}

.caption-list-text {
    text-align: left;
}

/* .caption-list-languages{
  max-height: 112px;
  overflow: auto;
}
.video-frame-separate .caption-list-languages{
  max-height: none;
  column-count: 3;
  column-gap: 22px;
} */
.caption-list-languages {
    max-height: none;
    column-count: 3;
    column-gap: 22px;
}

.caption-list-input {
    margin-left: 0;
    accent-color: #000;
}

.markers-list {
    display: none;
    position: absolute;
    bottom: calc(100% + 25px);
    right: 10px;
    z-index: 1;
    background-color: var(--hms-ui-colors-primaryLight);
    color: #fff;
    max-width: 315px;
    max-height: 320px;
    overflow-y: auto;
}

.markers-list.visible {
    display: block;
}

.markers-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.markers-list ul li {
    padding: 8px 12px;
    display: flex;
    font-weight: 600;
}

.markers-list-time {
    white-space: nowrap;
}

.markers-list ul li:hover {
    background-color: #000;
    cursor: pointer;
}

.markers-list-text {
    margin-left: 12px;
    text-align: left;
}

.plyr__progress__container .plyr__tooltip {
    background-color: #000;
    color: #fff;
    font-size: 14px;
    max-width: none !important;
    width: fit-content;
}

.d-none {
    display: none;
}

.video-lang-active {
    background-color: #000;
}

.replay-download {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.replay-download-btn {
    border: 1px solid var(--hms-ui-colors-primaryLightBorder);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    -moz-border-radius: 6px;
    color: var(--hms-ui-colors-light);
    font-size: 14px;
    display: flex;
    gap: 5px;
    padding: 6px 0px;
    margin: 3px auto;
    width: 160px;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
}

.replay-download-btn:hover {
    border-color: var(--hms-ui-colors-yellow);
    background: var(--hms-ui-colors-black);
    color: var(--hms-ui-colors-white);

}

@media(max-width: 1200px) {
    .click-for-sound-text {
        display: block;
    }

    .plyr__control--overlaid {
        border-width: 4px;
        height: 70px;
        width: 70px !important;
    }

    .plyr__control--overlaid svg {
        height: 26px;
        width: 26px;
    }
}

@media(max-width: 767px) {
    .video-wrap video {
        height: auto;
    }

    .markers-list {
        bottom: 100%;
        max-height: 130px;
    }

    .markers-list ul li {
        padding: 6px 8px;
        font-size: 12px;
    }

    .caption-list {
        max-height: 110px;
        bottom: 100%;
        max-width: 99vw;
        right: 0;
    }

    .video-frame-separate .caption-list {
        max-width: 99vw;
        right: 0;
        max-height: 240px;
    }

    .caption-list-single {
        font-size: 13px;
    }

    .caption-list-short-name {
        font-size: 10px;
        min-width: 30px;
    }

    .caption-list-single-label {
        padding: 5px 9px;
    }

    .caption-list-languages {
        column-count: 2;
        column-gap: 15px;
    }


    .caption-list-input {
        width: 10px;
    }


    .plyr__progress__marker {
        border-width: 1px;
        width: 6px;
        height: 6px;
    }

    .plyr {
        line-height: normal;
    }

    .plyr__control--overlaid {
        border-width: 3px;
        height: 55px;
        width: 55px !important;
        aspect-ratio: 1/1;
    }


    .plyr__caption {
        font-size: 10px;
    }

    .plyr__control--overlaid svg {
        width: 50%;
        height: 50%;
    }

    .click-for-sound-btn {
        padding: 0px 11px;
        height: 36px;
        font-size: 11px;
        top: 5px;
        right: 7px;
    }

    .click-for-sound img {
        width: 18px;
    }

    .plyr__control svg {
        width: 50%;
        height: 50%;
        min-width: 14px;
        min-height: 14px;
    }

    .plyr__control img {
        width: 14px;
    }

    .plyr__controls__item .plyr__control,
    button.plyr__controls__item {
        height: 36px;
        width: 24px;
        padding: 2px;
    }

    .plyr--video .plyr__controls {
        height: 40px;
    }

    .plyr__time {
        font-size: 10px;
    }

    .plyr--fullscreen-enabled .plyr__video-wrapper {
        display: flex;
        align-items: center;
    }

    .speed-control-area-num .x-btn,
    .speed-control-area {
        font-size: 11px;
    }

    .speed-control-area-row {
        padding: 4px 9px;
    }

    .speed-control-area {
        bottom: 36px;
    }

    .speed-control-area.speed-control-area-animated {
        bottom: 6px;
    }

}